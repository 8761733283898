import React, { useState, useEffect } from 'react';
import {
  Table,
  Spin,
  Button,
  Checkbox,
  Modal,
  message,
  Empty,
  Image,
  Input,
} from 'antd';
import Icon from '@/components/Icon';

import { spliceByIndex } from '@/utils';
import { useDispatch, history } from 'umi';
import CouponItem from '@/components/Goods/Coupon/item';
import ConfirmCoupon from '../ConfirmCoupon';
import GoodsRow, { GoodItem } from './GoodsRow';
const { TextArea } = Input;
import './ProductItem.less';

interface IProps {
  /**
   * 供应商名字
   */
  supplierName?: string;
  /**
   * 商品图片
   */
  img?: string;
  /**
   * 所有规格属性
   */
  skuAttributeVOList?: any[];
  /**
   * 单价
   */
  price?: number;
  /**
   * 小计
   */
  priceSubtotal?: number;
  /**
   * 数量
   */
  num?: number;
  /**
   * 是否是收银台模式
   */
  showDataFlag?: boolean;
  /**
   * 是否显示店铺名称旁边上的小计
   */
  isShowTitleSubtotal?: boolean;
  /**
   * 是否显示留言
   */
  isShowMessage?: boolean;
  /**
   * 是否显示优惠券
   */
  isShowCoupon?: boolean;
  /**
   * 目前只用在结算页面
   */
  goodType?: string;
  /**
   * 删除
   */
  handleGoodItemDelete: (val: any) => void;
  /**
   * 卖家留言
   */
  setBuyerLeaveMessage: (val: any) => void;
  /**
   * 点击标题
   */
  handleClickTitle: (val: any) => void;
  [props: string]: any;
}
function ProductItem(props: IProps) {
  const dispatch = useDispatch();
  const {
    purchaseList,
    showDataFlag,
    supplierName,
    supplierId,
    purchaseListVOList = [],
    purchaseSupplier,
    isShowTitleSubtotal = true,
    selectedRowKeys = [],
    setSelectedRowKeys,
    isShowMessage = true,
    isShowCoupon = true,
    isShoppingCoupon = false,
    goodType,
  } = props;

  const handleNumberStep = (val: any, item: GoodItem, supplierId: number) => {
    if (val === 0 || val === '0') return;
    if (val === '') {
      const newPurchaseList = JSON.parse(JSON.stringify(purchaseList));
      const parentIndex = newPurchaseList.findIndex(
        (purItem: any) => purItem.supplierId === supplierId,
      );
      const index = newPurchaseList[parentIndex].purchaseListVOList.findIndex(
        (purItem: any) => purItem.purchaseListId === item.purchaseListId,
      );
      const newItem = {
        ...item,
        num: val,
      };
      if (index > -1) {
        newPurchaseList[parentIndex].purchaseListVOList[index] = newItem;
      }
      dispatch({
        type: 'order/save',
        payload: {
          purchaseList: newPurchaseList || [],
        },
      });
    } else {
      dispatch({
        type: 'order/updatePurchaseNum',
        payload: {
          purchaseListId: item.purchaseListId,
          num: val < item.miniNum ? item.miniNum : val,
        },
      });
    }
  };
  // 店铺是否checked
  const isStoreChecked = React.useMemo(() => {
    // 获取每个商家下对应商品
    const selectedArr =
      (!showDataFlag &&
        purchaseListVOList.filter(
          (skuGood: any) =>
            (skuGood.status === 0 ||
              (skuGood.status === 1 && skuGood.stock > 0)) &&
            selectedRowKeys.includes(skuGood.skuId),
        )) ||
      [];
    const newArr = purchaseListVOList.filter(
      (item: any) =>
        (item.status === 0 || (item.status === 1 && item.stock > 0)) && item,
    );
    if (selectedArr.length === 0) return;
    // 每个商品的skuId数组比较
    const flag =
      selectedArr
        .map((item: any) => item.skuId)
        .sort()
        .toString() ===
      newArr
        .map((item: any) => item.skuId)
        .sort()
        .toString();
    return flag;
  }, [selectedRowKeys]);
  //
  const handleCheckChange = (e: any) => {
    const bool = e.target.checked;
    if (bool) {
      purchaseListVOList.map((skuGood: any) => {
        if (
          !selectedRowKeys.includes(skuGood.skuId) &&
          (skuGood.status === 0 || (skuGood.status === 1 && skuGood.stock > 0))
        ) {
          selectedRowKeys.push(skuGood.skuId);
        }
      });
    } else {
      purchaseListVOList.map((skuGood: any) => {
        if (
          selectedRowKeys.includes(skuGood.skuId) &&
          (skuGood.status === 0 || (skuGood.status === 1 && skuGood.stock > 0))
        ) {
          const indx = selectedRowKeys.findIndex(
            (id: number) => id === skuGood.skuId,
          );
          selectedRowKeys.splice(indx, 1);
        }
      });
    }
    setSelectedRowKeys([...selectedRowKeys]);
  };
  const [couponList, setCouponList] = useState(['', '']);

  useEffect(() => {
    if (isShowCoupon) {
      getCoupon();
    }
  }, [isShowCoupon]);
  // 获取优惠券
  const getCoupon = () => {
    const spuIds = purchaseListVOList.map((item: any) => item.spuId);
    if (spuIds.length === 0) {
      return;
    }
    dispatch({
      type: 'goods/couponListCanReceiveBySpuIds',
      payload: spuIds,
    }).then((data: any) => {
      setCouponList(data);
    });
  };
  const onCouponReveice = (index: number) => {
    setCouponList(spliceByIndex(index, couponList));
  };

  const itemAllPrice = React.useMemo(() => {
    const count: number = purchaseListVOList.reduce(
      (prev: number, item: any) => {
        return selectedRowKeys.includes(item.skuId)
          ? prev + item.num * item.price
          : prev;
      },
      0,
    );
    return Math.floor(count * 100) / 100;
  }, [selectedRowKeys, purchaseListVOList]);

  const couponSkuList = React.useMemo(() => {
    return purchaseSupplier && purchaseSupplier.skuList
      ? purchaseSupplier.skuList.map((item: any) => ({
          skuId: item.skuId,
          spuId: item.spuId,
          num: item.num,
        }))
      : [];
  }, [purchaseSupplier]);

  const handleDelete = (purchaseListId: number) => {
    props.handleGoodItemDelete && props.handleGoodItemDelete(purchaseListId);
  };
  return (
    <div className="product-item shopping">
      <div className="p-i_header">
        <div className="h-info">
          {!showDataFlag ? (
            <Checkbox checked={isStoreChecked} onChange={handleCheckChange} />
          ) : (
            <></>
          )}
          <Icon className="p-icon" type="icondianpu-youzengpin" />
          <span
            className="company-title"
            onClick={() => history.push(`/shop/${supplierId}/category`)}
          >
            {supplierName}
          </span>
          {isShowCoupon && couponList.length > 0 && isShoppingCoupon && (
            <div className="coupon-box">
              <CouponItem
                list={couponList}
                onReveice={onCouponReveice}
                title="优惠券"
                type="reveice"
                onInitList={getCoupon}
              />
            </div>
          )}
        </div>
        {!showDataFlag && isShowTitleSubtotal ? (
          <>
            <div className="h-total-price">
              <span>¥{itemAllPrice}</span>
              {/* <span className="preferential">优惠: -¥10</span> */}
            </div>
            <div></div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="p-i_goods">
        {purchaseListVOList &&
          purchaseListVOList.map((purchase: any, index: number) => (
            <GoodsRow
              key={index + '-volist'}
              {...purchase}
              showDataFlag={showDataFlag}
              handleNumberStep={handleNumberStep}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              handleItemDelete={handleDelete}
              handleChildrenClickTitle={props.handleClickTitle}
              supplierId={supplierId}
              goodType={goodType}
            />
          ))}
        {purchaseSupplier &&
          purchaseSupplier.skuList.map((purchase: any, index: number) => (
            <GoodsRow
              key={index + '-skuList'}
              {...purchase}
              showDataFlag={showDataFlag}
              handleNumberStep={handleNumberStep}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              handleItemDelete={handleDelete}
              handleChildrenClickTitle={props.handleClickTitle}
              supplierId={supplierId}
              goodType={goodType}
            />
          ))}
        {showDataFlag ? (
          <>
            {isShowCoupon && (
              <ConfirmCoupon
                skuList={couponSkuList}
                onChange={props.onCouponChange}
              />
            )}
            {isShowMessage && (
              <div className="clear-out-con">
                <div className="remark-con">
                  <div className="section-title">给卖家留言：</div>
                  <TextArea
                    className="textarea"
                    rows={3}
                    showCount
                    maxLength={150}
                    placeholder="请输入留言"
                    onChange={(e) =>
                      props.setBuyerLeaveMessage({
                        tenantId: supplierId,
                        buyerLeaveMessage: e.target.value,
                      })
                    }
                  ></TextArea>
                </div>
                <div className="clear-list">
                  <p>
                    货品金额：<span>￥{purchaseSupplier?.totalPrice || 0}</span>
                  </p>
                  <p>
                    运费：<span>￥{purchaseSupplier?.freightPrice || 0}</span>
                  </p>
                  <p>
                    优惠：
                    <span>
                      ￥
                      {purchaseSupplier?.couponPrice ||
                        purchaseSupplier?.discountPrice ||
                        0}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ProductItem;
